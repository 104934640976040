<template>
  <div>
    <!--  Cover Section -->
    <section
      ref="cover"
      class="section cover-section">
      <CoverTransition>
        <div class="section-bg center-bg"></div>
      </CoverTransition>
      <SplitTitleTransition>
        <h2 class="split-title">GLOBAL No.1 @ OPEN INNOVATION HUB</h2>
      </SplitTitleTransition>
    </section>
    <!--  // Cover Section -->
    <!--  Visual Section -->
    <section id="visual-section" class="section visual-section">
      <div class="section-bg square-bg">
        <div class="title-wrap">
          <h2 class="ani-text-up">63 FINTECH CENTER</h2>
          <div class="title-desc ani-text-up" v-html="$t(`content.center.Fintech.txt01`)">
          </div>
        </div>
      </div>
    </section>
    <!--  // Visual Section -->

    <!-- FACILITIES -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3 class="center">FACILITIES</h3>
        </div>
        <BaseTab
          v-model="tabValue"
          v-bind:list="facilities"
          tabtype="small-tab"
          @input="tabCallback"
        >
          <template v-slot="Scope">
            <li class :class="Scope.paramClass">{{Scope.title}}</li>
          </template>
        </BaseTab>
        <div class="image-area facilities">
          <!-- 이미지 슬라이더 -->
          <BaseSlider
            class="s2"
            ref="facilitieSlider"
            :autoplay="false"
            :list="facilitieSliderList"
            :pagination="true"
            :navigation="true"
            @change="onChange"
          >
            <template v-slot="{ item }">
              <img :src="item.img" :alt="`${item.title} 이미지`" />
            </template>
          </BaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
      </div>
    </section>
    <!-- // FACILITIES -->

    <!-- MEMBERS -->
    <section class="grey-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>MEMBERS</h3>
          <div class="title-desc" v-html="$t(`content.center.Fintech.txt02`)">

          </div>
        </div>
        <!-- Member List -->
        <div class="card-list-wrap">
          <div class="card-list">
            <StaggerTransition targets=".card-wrap">
              <GroupedSlider
                :pagination="false"
                :autoplay="false"
                :loop="false"
                :list="memberList"
                :itemsPerGroup="12"
              >
                <template v-slot="{ item }">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="item.img" :src="item.img" :alt="item.title" />
                      <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                    </div>
                  </div>
                </template>
              </GroupedSlider>
            </StaggerTransition>
          </div>
        </div>
        <!-- // Member List -->
      </div>
    </section>
    <!-- // MEMBERS -->

    <!-- Contact -->
    <section class="inner-section">
      <div class="inner-sm">
        <div class="title-wrap">
          <h3>Contact</h3>
          <div class="title-desc">{{$t(`content.center.Fintech.txt03`)}}</div>
        </div>
        <div class="location-wrap" data-aos="fade-up">
          <div class="info-area">
            <dl class="square">
              <dt>LOCATION</dt>
              <dd>{{$t(`content.center.Fintech.txt04`)}}</dd>
              <dt>E-MAIL</dt>
              <dd>
                <ExternalLink
                  to="mailto:63fintech@dreamplus.asia"
                >63fintech@dreamplus.asia</ExternalLink>
              </dd>
              <dt>DIRECTION</dt>
              <dd>
                <dl class="direction">
                  <dt>{{$t(`content.center.Fintech.txt05`)}}</dt>
                  <dd>
                    <ul class="subway">
                      <li>{{$t(`content.center.Fintech.txt06`)}}{{$t(`content.center.Fintech.txt07`)}}</li>
                      <li>{{$t(`content.center.Fintech.txt08`)}}</li>
                    </ul>
                  </dd>
                  <dt>{{$t(`content.center.Fintech.txt09`)}}</dt>
                  <dd>
                    <ul class="bus">
                      <li>{{$t(`content.center.Fintech.txt10`)}}</li>
                      <li>{{$t(`content.center.Fintech.txt11`)}}</li>
                      <li>{{$t(`content.center.Fintech.txt12`)}}</li>
                    </ul>
                  </dd>
                </dl>
              </dd>
              <dt>TEL</dt>
              <dd>02-789-7306</dd>
            </dl>
          </div>
          <div class="map-area">
            <img src="~@/assets/images/center/map-63-fintech-center.png" alt="63-fintech-center" />
            <div class="marker-wrap square">
              <span class="marker" data-aos="zoom-in" data-aos-delay="300"></span>
              <span class="branch-name">63 Fintech center</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Contact -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import GroupedSlider from '@/components/slider/GroupedSlider.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import CoverTransition from '@/components/transition/CoverTransition.vue';
import SplitTitleTransition from '@/components/transition/SplitTitleTransition.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';

import { gsap } from '@/common/motions';

export default {
  name: 'Square',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseSlider,
    BaseTab,
    GroupedSlider,
    CoverTransition,
    SplitTitleTransition,
    StaggerTransition,
    ExternalLink,
  },
  data() {
    return {
      tabValue: 0,
      facilities: ['BUSINESS LOUNGE', 'OFFICE'],
      facilitieSlider: undefined,
      facilitieSliderIndex: undefined,
      facilitieSliderList: [
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/facilities/63-Business-Lounge-Photo1.jpg',
          ),
          title: 'BUSINESS LOUNGE1',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/facilities/63-Business-Lounge-Photo2.jpg',
          ),
          title: 'BUSINESS LOUNGE2',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/facilities/63-Office-Photo1.jpg',
          ),
          title: 'OFFICE1',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/facilities/63-Office-Photo2.jpg',
          ),
          title: 'OFFICE2',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/facilities/63-Office-Photo3.jpg',
          ),
          title: 'OFFICE3',
        },
      ],
      memberList: [
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/bomapp.png',
          ),
          title: '보맵',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/s-sen-stone.png',
          ),
          title: '센스톤',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/agile-soda.png',
          ),
          title: '애자일소다',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/deep-search.png',
          ),
          title: '딥서치',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/sentbe.png',
          ),
          title: '센트비',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/wadiz.png',
          ),
          title: '와디즈',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/sbcn.png',
          ),
          title: 'SBCN',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/quantec.png',
          ),
          title: '콴텍',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/doomoolmori.png',
          ),
          title: '두물머리',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/rezi.png',
          ),
          title: '레지',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/whosgood.png',
          ),
          title: '후즈굿',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/bluevisor.png',
          ),
          title: '블루바이저',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/g-bike.png',
          ),
          title: '지바이크',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/welgram.png',
          ),
          title: '웰그램',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/sentience.png',
          ),
          title: '센티언스',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/emolize.png',
          ),
          title: '에몰라이즈',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/finda.png',
          ),
          title: '핀다',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/humanscape.png',
          ),
          title: '휴먼스케이프',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/luxrobo.png',
          ),
          title: '럭스보로',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/tenqube.png',
          ),
          title: '텐큐브',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/zufintech.png',
          ),
          title: '핀테크',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/qara.png',
          ),
          title: '콰라',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/learninvest.png',
          ),
          title: '런인베스트',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/intelli-quant.png',
          ),
          title: '인텔리퀀트',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/withfund.png',
          ),
          title: '위드펀드',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/swallaby.png',
          ),
          title: '스왈라비',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/data-universe.png',
          ),
          title: '데이터유니버스',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/tanker.png',
          ),
          title: '탱커',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/haechilabs.png',
          ),
          title: '해치랩스',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/hiddentrack.png',
          ),
          title: '히든트랙',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/letter-fly.png',
          ),
          title: '레터플라이',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/zipadvisor.png',
          ),
          title: '집펀드',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/habitfactory.png',
          ),
          title: '해빗팩토리',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/tndn.png',
          ),
          title: 'TNDN',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/heybit.png',
          ),
          title: '헤이비트',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/mindset.png',
          ),
          title: '마인드셋',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/mastercompany.png',
          ),
          title: '마스터컴퍼니',
        },
        {
          img: this.requireAssetsImage(
            'center/63-fintech-center/members/fmsoft.png',
          ),
          title: 'FMSOFT',
        },
      ],
    };
  },
  mounted() {
    this.facilitieSlider = this.$refs.facilitieSlider;

    gsap.from('.square-bg', {
      scrollTrigger: '.visual-section',
      scale: 1.2,
      autoAlpha: 0,
      duration: 2.8,
      ease: 'Power4.Inout',
    });
  },
  watch: {
    $route() {
      const { hash } = this.$route;
      if (!hash) {
        document.querySelector('.cover-section').classList.remove('hide');
        this.AOSreset();
      }
    },
  },
  methods: {
    /**
     * AOS init
     * 작동원리: [resize] 이벤트를 강제로 발생시켜 리셋시킴..
     */
    AOSreset() {
      if (window.dispatchEvent) {
        window.dispatchEvent(new Event('resize', { bubbles: true, cancelable: true }));
      } else {
        window.fireEvent('resize');
      }
    },
    onChange(v) {
      this.tabValue = this.facilities.indexOf(this.facilitieSliderList[v].title.replace(/[1-9]/g, ''));
    },
    tabCallback() {
      this.facilitieSliderList.some((v, i) => {
        const facilitieTitle = v.title.replace(/[1-9]/g, '');
        const bool = facilitieTitle === this.facilities[this.tabValue] && true;
        if (bool) {
          this.tabValue = i;
        }
        return bool;
      });
      const tmp = this.tabValue + 1;
      this.facilitieSlider.$el.swiper.slideTo(tmp);
    },
    /**
     * hash 파라미터 변경시 처리
     * @see mixinHelperUtils의 beforeRouteEnter 가드, beforeRouteUpdate 가드
     */
    onChangeRouteHash(hash) {
      // 해당 hash(id)로 스크롤 이동
      if (hash === '#visual-section') {
        document.querySelector(hash).previousElementSibling.classList.add('hide');
        this.AOSreset();
      }
    },
  },
};
</script>
<style>
</style>
