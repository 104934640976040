<template>
  <transition
    :css="false"
    appear
    @appear="onAppear"
  >
    <slot></slot>
  </transition>
</template>

<script>
import { gsap, ScrollTrigger } from '@/common/motions';

/**
 * Stagger Transition 컴포넌트
 * @see https://greensock.com/docs/v3/Staggers
 * @see https://vuejs.org/v2/guide/transitions.html#JavaScript-Hooks
 * @see https://vuejs.org/v2/guide/transitions.html#Transitions-on-Initial-Render
 * @see ScrollTrigger https://greensock.com/docs/v3/Plugins/ScrollTrigger
 */
export default {
  name: 'StaggerTransition',

  props: {
    /**
     * 진행 방향
     * @values up, right
     */
    direction: {
      type: String,
      default: 'up',
      validator: (value) => [
        'up',
        'right',
      ].indexOf(value) !== -1,
    },

    /**
     * 대상 (하위 요소 selector)
     */
    targets: {
      type: String,
      default: 'li',
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `st-${Math.random().toString(36).slice(2)}`;
      },
    },

    /**
     * 지연
     */
    delay: {
      type: Number,
      default: 0.5,
    },
  },

  methods: {
    onAppear(el, done) {
      const targets = el.querySelectorAll(this.targets);

      const vars = {
        scrollTrigger: {
          id: this.id,
          trigger: el,
          // markers: true,
          // onEnter: () => {
          //   console.log('onEnter', this.id);
          // },
        },
        autoAlpha: 0,
        stagger: 0.15,
        duration: 0.66,
        delay: this.delay,
        ease: 'Back.easeOut',
      };

      if (this.direction === 'up') {
        // 위로 진행하는 경우
        vars.y = 50;
      } else if (this.direction === 'right') {
        // 오른쪽으로 진행하는 경우
        vars.x = -50;
      }

      gsap.from(
        targets,
        vars,
      );

      done();
    },
  },

  destroyed() {
    const trigger = ScrollTrigger.getById(this.id);
    if (trigger) {
      trigger.kill();
    }
  },

};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 (위로 진행) 예시 :
```jsx
<div class="card-list-wrap">
  <StaggerTransition>
    <ul class="card-list ani-stagger">
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-1.png" alt="현대">
          <span class="blind">현대</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-2.png" alt="이랜드">
          <span class="blind">이랜드</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-3.png" alt="교원">
          <span class="blind">교원</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-4.png" alt="예스24">
          <span class="blind">예스24</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-5.png" alt="고대병원">
          <span class="blind">고대병원</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-6.png" alt="바이엘">
          <span class="blind">바이엘</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-7.png" alt="신패스">
          <span class="blind">신패스</span>
        </div>
      </li>
      <li>
        <div class="card">
          <img src="/images/programs/partner/withpartner-8.png" alt="콘텐츠진흥원">
          <span class="blind">콘텐츠진흥원</span>
        </div>
      </li>
    </ul>
  </StaggerTransition>
</div>
```

진행 방향 설정 (오른쪽으로 진행) 예시 :
```jsx
<section class="hash-tag-section">
  <StaggerTransition
    direction="right"
  >
    <ul class="hash-tag-wrap ani-stagger-right">
      <li><a class="hash-tag on"># 전체</a></li>
      <li><a class="hash-tag"># 뉴스</a></li>
      <li><a class="hash-tag"># 인터뷰</a></li>
      <li><a class="hash-tag"># 행사리뷰</a></li>
      <li><a class="hash-tag"># 기타</a></li>
    </ul>
  </StaggerTransition>
</section>
```
</docs>
