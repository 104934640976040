<template>
  <transition
    :css="false"
    appear
    @appear="onAppear"
  >
    <slot></slot>
  </transition>
</template>

<script>
import { gsap, ScrollTrigger } from '@/common/motions';

/**
 * 타이틀 분리 Transition 컴포넌트
 * @see https://vuejs.org/v2/guide/transitions.html#JavaScript-Hooks
 * @see https://vuejs.org/v2/guide/transitions.html#Transitions-on-Initial-Render
 * @see ScrollTrigger https://greensock.com/docs/v3/Plugins/ScrollTrigger
 */
export default {
  name: 'SplitTitleTransition',

  props: {
    /**
     * 분리되어 생성되는 하위 요소 태그
     */
    itemTag: {
      type: String,
      default: 'span',
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `split-${Math.random().toString(36).slice(2)}`;
      },
    },
  },

  methods: {
    onAppear(el, done) {
      el.innerHTML = el.textContent
        .split('')
        .map((char) => ((char !== '@') ? `<${this.itemTag}>${char}</${this.itemTag}>` : '<br>'))
        .join('');

      gsap.from(
        el.querySelectorAll(this.itemTag),
        {
          scrollTrigger: {
            id: this.id,
            trigger: el,
            // markers: true,
            // onEnter: () => {
            //   console.log('onEnter', this.id);
            // },
          },
          duration: 1,
          opacity: 0,
          stagger: 0.1,
          ease: 'Back.easeOut',
        },
      );

      done();
    },
  },

  destroyed() {
    const trigger = ScrollTrigger.getById(this.id);
    if (trigger) {
      trigger.kill();
    }
  },
};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<SplitTitleTransition>
  <h2 class="split-title">BUSINESS ENABLER DREAMPLUS</h2>
</SplitTitleTransition>
```
</docs>
